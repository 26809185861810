import React from "react";

import { TonConnectUIProvider } from '@tonconnect/ui-react';

import { SDKProvider } from '@telegram-apps/sdk-react';

import { Toaster } from "react-hot-toast";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import TemplateLayout from "./components/Layout";
import Home from "./page/Home";
import RoundDetail from "./page/RoundDetail";
import Wallet from "./page/Wallet";
import History from "./page/History";


function App() {
  return (
      <TonConnectUIProvider manifestUrl={process.env.REACT_APP_MANIFESTURL}>
        {/*Root component*/}
        <SDKProvider acceptCustomStyles >
          <Router>
            <TemplateLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/round/:roundId" element={<RoundDetail />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/history" element={<History />} />
              </Routes>
            </TemplateLayout>
          </Router>
          <Toaster
            position="top-center"
            toastOptions={{
              duration: 2500,
            }}
          />
        </SDKProvider>
      </TonConnectUIProvider>
  );
}

export default App;

import axios from "axios";
// import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';

const api_endpoint = process.env.REACT_APP_API;

export const getUser = async (teleId: String) => {
    try {
        const result = await axios.get(`${api_endpoint}/users/${teleId}`);
        return result.data.data
    } catch (error) {
        console.log(`error`, error);
        return
    }
}

export const addUser = async (teleUser, refFrom, initDataRaw) => {
  const result = await axios.post(`${api_endpoint}/users/create`, {
      tele_id: teleUser.id,
      tele_username: teleUser.username ? teleUser.username : teleUser.id,
      name: `${teleUser.firstName} ${teleUser.lastName}`,
      ref_from: refFrom
    }, {
      headers: {
        'Authorization': `tma ${initDataRaw}` 
      }
    }
  );
  return result.data.user;
};

export const getCurrentRound = async (userId) => {
  try {
    const result = await axios.post(`${api_endpoint}/app/current`, {
      user_id: userId
    }, {
      headers: {
        // 'Authorization': `tma ${initDataRaw}` 
      }
    }
  );
    return result.data.data
  } catch (error) {
    console.log(`error`, error);
    return
  }
}

export const getRoundDetail = async (roundId, userId) => {
  
  try {
    const result = await axios.post(`${api_endpoint}/app/round`, {
      roundId: roundId,
      userId: userId
    }, {
      headers: {
        // 'Authorization': `tma ${initDataRaw}` 
      }
    }
  );
    return result.data.data
  } catch (error) {
    console.log(`error`, error);
    return
  }
}

export const getHistory = async (status: string) => {
  try {
    const result = await axios.get(`${api_endpoint}/app/history`);
    return result.data.data
  } catch (error) {
    console.log(`error`, error);
    return
  }
}

export const withdraw = async (userId: number, amount: number, toWallet: string, initDataRaw) => {
    try {
        const result = await axios.post(`${api_endpoint}/wallet/withdraw`, {
            user_id: userId,
            amount: amount,
            to_wallet: toWallet
          }, {
            headers: {
              'Authorization': `tma ${initDataRaw}` 
            }
          }
        );

        return {
            status: `success`,
            message: `Withdrawal is being processed.`,
            data: result.data.txn
        };

    } catch (error) {
        console.log(`error`, error)
        return {
            status: `error`,
            message: `Unable to Withdrawal.`
        };
    }
};

export const addFund = async (userId: number, amount: number, currentRound: number, initDataRaw) => {
  try {
      const result = await axios.post(`${api_endpoint}/app/deposit`, {
          user_id: userId,
          amount: amount,
          current_round: currentRound
        }, {
          headers: {
            'Authorization': `tma ${initDataRaw}` 
          }
        }
      );
      return {
        status: `success`,
        message: `Added.`,
        data: result.data.data
      };

  } catch (error) {
      console.log(`error`, error)
      return {
        status: `error`,
        message: `Unable to add fund.`
      };
  }
};

export const getTonTxnByUser = async (userId: number) => {
    const result = await axios.get(`${api_endpoint}/wallet/txns/${userId}`);
    return result.data.txns
}
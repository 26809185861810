import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { Modal, Form, Input } from "antd";
import { deposit } from "../../web3/ton";
import { withdraw } from "../../web3/app";
import { getTx, sleep, getTonBalance, truncateString, getAvatar, getTxnType } from '../../web3/ultils';
import toast from "react-hot-toast";
import { TonConnectButton } from '@tonconnect/ui-react';
import dayjs from "dayjs";
import { Table } from "antd";
import ReactLoading from "react-loading";

import tonLogo from "../../assets/img/ton.png";
import point from "../../assets/img/logo.png";

import logo from "../../assets/img/logo.svg";

import { getUser, getTonTxnByUser } from '../../web3/app';

const systemAddress = process.env.REACT_APP_SYSTEM_ADDRESS;

export default function Wallet() {
    const navigate = useNavigate();
    const [tonConnect] = useTonConnectUI();
    const walletAddress = useTonAddress();
    const [userData, setUserData] = useState<any>();
    const [tonBalance, setTonBalance] = useState(0);
    const [tonAmount, setTonAmount] = useState(0);
    const [depositForm] = Form.useForm();
    const [txnsData, setTxnsData] = useState<any>();

    const [isDepositModalOpen, setDepositModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const { initDataRaw } = retrieveLaunchParams();

    // let initDataRaw;
    let teleUser;

    teleUser = {
        id: `740772070`,
        username: `judasle7`,
        firstName: `Judas`,
        lastName: `L`
    };

    const initData = useInitData();
    teleUser = initData.user;

    const handleCancelDepositModal = () => {
        setDepositModalOpen(false);
    };

    const txnsColumns = [
        {
            title: "TXID",
            dataIndex: "code",
            key: "code",
            render: (_, record) => {
                return (
                    <>
                        {record.code}
                        <p className="text-[#91abc9] text-xs">{dayjs(record.created_at.toString()).format("DD MMM YYYY, HH:mm:ss")}{" "}</p>
                    </>                   
                );
            }
        },
        {
            title: "Type",
            dataIndex: "txn_type",
            key: "txn_type",
            render: (_, record) => {
                return (
                    <>
                        {getTxnType(record.txn_type).text}
                        <p className="text-[#91abc9] text-xs">
                            {
                            record.round_id > 0 &&
                                <>Round #{record.round_id}</>
                            }
                        </p>
                    </>                   
                );
            }
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            align: 'right' as const,
            render: (_, record) => {
                return (
                    <div className="amount">
                        <div className={`flex gap-2 items-center justify-end ${getTxnType(record.txn_type).type}`}>{getTxnType(record.txn_type).type == 'plus' ? '+' : '-'}
                            {record.amount.toLocaleString('en-US')}
                            <img className="w-[20px]" src={tonLogo} />
                        </div>
                        <div className={`uppercase text-xs text-[#91abc9] text-right ${record.status}`}>{record.status}</div>
                    </div>
                )
            }
        }
    ];

    const fetchTonBalance = async () => {
        setTonBalance(await getTonBalance(walletAddress))
    }

    const fetchUser = async () => {
        const user = await getUser(teleUser.id);
        if(user){
            const txns = await getTonTxnByUser(user.id);
            setTxnsData(txns);
            setUserData({...user, txns});
        }else{
            navigate("/", { replace: true });
        }
      };
    
    useEffect(() => {
        fetchTonBalance();
        const interval = setInterval(() => {
            fetchTonBalance();
            fetchUser();
        }, 15000);
        return () => clearInterval(interval);
    }, [walletAddress]);

    useEffect(() => {
        fetchUser();

        // const interval = setInterval(() => {
        //     scanTxn();
        // }, 3000);
        // return () => clearInterval(interval);
        // scanTxn();

	}, []);

    // console.log(userData);

    const handleDeposit = async () =>{
        // check balance
        const tonBalance = await getTonBalance(walletAddress);
        if(tonAmount == 0 || tonAmount + 0.01 > tonBalance){
            toast.error(`Insufficient balance.`);
            return;
        }

        const deposited = await deposit(userData.tele_id, tonAmount, walletAddress, tonConnect);
        console.log(`deposited`, deposited)
        if (deposited.status == "success") {
            setDepositModalOpen(true);
            await sleep(15000);
            setIsChecked(true);
        } else {
            setDepositModalOpen(false);
            toast.error(deposited.message);
        }
    }

    const handleWithdraw = async () => {
        await fetchUser();
        if(tonAmount == 0 || tonAmount > userData.ton){
            toast.error(`Insufficient balance.`);
            return;
        }
        const withdrawned = await withdraw(userData.id, tonAmount, walletAddress, initDataRaw);
        console.log(`withdrawned`, withdrawned)
        if (withdrawned.status == "success") {
            toast.success(withdrawned.message);
        } else {
            toast.error(withdrawned.message);
        }
    }

    const handleDisconnect = async () => {
        await tonConnect.disconnect();
    }

    const scanTxn = async () =>{
        const txn = await getTx(`0QDBPJCKVTaPmd-gCc2Vvm2iVmtwhzlykBHiQI2zqu1QwYVn`);
    }

    return(
        <>
            {
                userData &&
                <div className="text-white mb-5 flex items-center justify-between gap-3">
                    <div>
                        <img className="w-[50px] lg:w-[80px] rounded-full" src={getAvatar(userData.tele_username)} />
                    </div>
                    <div className="flex-1">
                        <h2 className="font-bold text-[18px] mb-1">{ userData.name } </h2>
                        <p className="text-sm text-[#91abc9]">@{ userData.tele_username }</p>
                    </div>
                    <div>
                        <div className="flex items-center justify-end gap-2 text-[20px]">
                            <div>{ userData.ton.toLocaleString('en-US') }</div>
                            <img className="w-[20px]" src={tonLogo} />
                        </div>
                        <div className="flex items-center justify-end gap-2 text-[20px]">
                            <div>{ userData.point.toLocaleString('en-US') }</div>
                            <img className="w-[20px]" src={point} />
                        </div>
                    </div>
                </div>
            }
            {
                walletAddress ? 
                <>
                    <div className="bg-[#1c0d34] rounded-[12px] p-4 text-white mb-5 flex items-end justify-between gap-2">
                        <div>
                            <h2 className="font-bold text-[16px] mb-1">Connected Wallet</h2>
                            { truncateString(walletAddress.toString(), 4, 8) }
                            <div className="flex items-center gap-2 text-[16px] mt-3">
                                <div>{tonBalance.toLocaleString('en-US')}</div>
                                <img className="w-[20px]" src={tonLogo} />
                            </div>
                        </div>
                        
                        <button
                            className="px-2 text-xs py-2 rounded-md text-white bg-secondary-gradient flex justify-center items-center gap-2"
                            onClick={() => {
                                handleDisconnect()
                            }}
                        >
                            <span>Disconnect</span>
                        </button>
                    </div>
                </>
                :
                <div className="bg-[#1c0d34] rounded-[12px] p-4 py-10 text-white flex items-center justify-center mb-5">
                    <TonConnectButton className="ton-connect-button" />
                </div>
            }

            <div 
                className={`bg-[#1c0d34] rounded-[12px] p-4 text-white ${walletAddress ? ``:`opacity-50 pointer-events-none`}`}
                >
                <div className="deposit">
                    <Form form={depositForm} layout="vertical">
                        <Form.Item
                            label="DEPOSIT / WITHDRAW"
                            name="tonAmount"
                            >
                            <Input 
                                type="number"
                                placeholder="TON AMOUNT"
                                value={tonAmount}
                                onChange={(e) => {
                                    setTonAmount(parseFloat(e.target.value));
                                    }}
                                />
                        </Form.Item>
                    </Form>
                    <div className="flex mt-2 gap-2">
                        <button
                            className="px-3 text-sm py-2 rounded-lg text-white bg-primary-gradient flex justify-center items-center gap-2"
                            onClick={() => {
                                handleDeposit()
                            }}
                        >
                            <span>DEPOSIT</span>
                        </button>
                        <button
                            // disabled
                            className="px-3 text-sm py-2 rounded-lg text-white bg-secondary-gradient flex justify-center items-center gap-2"
                            onClick={() => {
                                handleWithdraw()
                            }}
                        >
                            <span>WITHDRAW</span>
                        </button>
                    </div>
                </div>
            </div>
            

            {
                txnsData &&

                <>
                    <h2 className="font-bold text-[16px] mb-2  mt-10">Transactions</h2>
                    <Table
                        className="w-full table-rewards"
                        dataSource={txnsData }
                        columns={txnsColumns}
                        scroll={{ x: "max-content" }}
                        pagination={{
                            defaultPageSize: 10,
                            total: txnsData.length,
                            position: ["bottomCenter"],
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                            onClick: () => {}
                            };
                        }}
                    />
                </>
            }

            <Modal
              forceRender
              open={isDepositModalOpen}
              onCancel={handleCancelDepositModal}
              footer={false}
              className="modal-create-token"
            >
              <div className="text-center mt-6">
                <div className="checking mb-5">
                    {
                    !isChecked ? 
                      <>
                        <div className="flex justify-center mb-5">
                          <img className="w-[166px]" src={logo} />
                        </div>
                        <h3 className="font-medium text-[20px] text-white mb-5">Processing...</h3>
                        <div className="flex justify-center">
                          <ReactLoading type="bars" height={30} width={30} color="#ffffff" />
                        </div>
                      </>
                      : 
                      <>
                        <h3 className="font-medium text-[20px] text-white mb-5">Deposit order is being processed.</h3>
                        <button
                          onClick={handleCancelDepositModal}
                          className="px-3 text-sm py-2 rounded-lg text-white bg-primary-gradient">
                          <span>Close</span>
                        </button>
                      </>
                    }
                </div>
              </div>
              
          </Modal>
        </>
    );
}
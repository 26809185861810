import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Layout, Modal } from "antd";
import ReactLoading from "react-loading";
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { initUtils } from '@telegram-apps/sdk';
import axios from "axios";
import toast from "react-hot-toast";
import { getAvatar } from "../../web3/ultils";
import tele from "../../assets/img/tele.png";
import logo from "../../assets/img/logo.svg";

import { getUser, addUser } from '../../web3/app';

const { Content, Header } = Layout;

const TemplateLayout = ({ children }) => {

  const navigate = useNavigate();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isRefModalOpen, setRefModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [userData, setUserData] = useState();

  const { initDataRaw } = retrieveLaunchParams();
  // let initDataRaw;
  let teleUser;
  let ref_from;
  let utils;

  teleUser = {
    id: `740772070`,
    username: `judasle7`,
    firstName: `Judas`,
    lastName: `L`
  };
  ref_from = ``;

  utils = initUtils();
  const initData = useInitData();
  teleUser = initData.user;
  ref_from = initData.startParam;

  // console.log(`teleUser`, teleUser);
  // console.log(`ref_from`, ref_from);

  useEffect(() => {
    fetchUser();
  }, []);

  const handleCancelLoginModal = () => {
    setLoginModalOpen(false);
  };

  const handleCancelRefModal = () => {
    setRefModalOpen(false);
  };

  const handleLogin = async () => {
    const u = await getUser(teleUser.id);
    if(!u){
      setChecking();
      const newUser = await addUser(teleUser, ref_from, initDataRaw);
      console.log(`newUser`, newUser);
      if(newUser){
        setUserData(newUser);
      }
    }
  }
  const handleLoggedIn = () => {
    setLoginModalOpen(false);
    navigate("/wallet", { replace: true });
  }
  
  const setChecking = async () => {
    setLoginModalOpen(true);
    const interval = setInterval(() => {
      setIsChecked(true);
    }, 5000);
    return () => clearInterval(interval);
  }

  const fetchUser = async () => {
    const user = await getUser(teleUser.id);
    if(user){ setUserData(user) }
  };

  return (
    <Layout className="relative min-h-screen overflow-hidden game-layout">
      <Layout>
        <Header
          className={`!fixed z-10 w-[100%] right-0 w-full px-4`}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <NavLink to="/" >
            <img src={logo} className="w-[166px]" />
          </NavLink>
          <div className="flex justify-end items-center gap-5">
            {/* <button
              className="px-3 text-sm py-2 rounded-lg text-white border-[1px] border-[#91abc930] flex justify-center items-center gap-2"
              onClick={(e) => setRefModalOpen(true)}
            >
              <img src={tele} className="w-[16px]" />
              <span>Invite</span>
            </button> */}
            {
              userData ?
              <NavLink to="/wallet" className="player flex items-center gap-2 text-[#fff] uppercase">
                <img src={getAvatar(userData.tele_username)} className="w-[30px] h-[30px] rounded-full" />
                <div className="font-bold">@{userData.tele_username}</div>
              </NavLink>
              :
              <button
                className="px-3 text-sm py-2 rounded-lg text-white bg-primary-gradient flex justify-center items-center gap-2"
                onClick={(e) => handleLogin()}
              >
                <img src={tele} className="w-[16px]" />
                <span>Join now</span>
              </button>
            }
          </div>
        </Header>
        <Content className="px-3 pt-[80px]" >
          <div className="container mx-auto">
            {children}
          </div>
          <Modal
              forceRender
              open={isLoginModalOpen}
              onCancel={handleCancelLoginModal}
              footer={false}
              className="modal-create-token"
          >
              <div className="text-center mt-6">
                <div className="checking mb-5">
                    {
                    !isChecked ? 
                      <>
                        <div className="flex justify-center mb-5">
                          <img className="w-[166px]" src={logo} />
                        </div>
                        <h3 className="font-medium text-[20px] text-white mb-5">Checking your account</h3>
                        <div className="flex justify-center">
                          <ReactLoading type="bars" height={30} width={30} color="#ffffff" />
                        </div>
                      </>
                      : 
                      <>
                        <div className="flex justify-center mb-5">
                          <img className="w-[90px] rounded-lg" src={getAvatar(userData.tele_username)} />
                        </div>
                        <h3 className="font-medium text-[20px] text-white mb-5">Hi, @{userData.tele_username}</h3>
                        <button
                          onClick={() => handleLoggedIn()}
                          className="px-3 text-sm py-2 rounded-lg text-white bg-primary-gradient">
                          <span>Continue</span>
                        </button>
                      </>
                    }
                </div>
              </div>
              
          </Modal>
          <Modal
                forceRender
                open={isRefModalOpen}
                onCancel={handleCancelRefModal}
                footer={false}
                className="modal-create-token"
            >
                <div className="text-center mt-6">
                  
                  <div className="mt-7 px-3">
                      <div className="mt-4 text-[17px] text-center text-white" >
                          Tap on the button<br />to invite your friends
                      </div>

                      <button
                          className="px-3 py-2 rounded-lg text-white border-[1px] border-[#91abc930] flex justify-center items-center gap-2 w-full mt-7"
                          onClick={() => {
                              navigator.clipboard.writeText(`${process.env.REACT_APP_STARTAPP}?startapp=${teleUser?.id}`);
                              toast.success("Copied!");
                            }}
                        >
                          <span>Copy invite link</span>
                      </button>
                      <button
                          className="px-3 py-2 rounded-lg text-white border-[1px] border-[#91abc930] flex justify-center items-center gap-2 w-full mt-2"
                          onClick={ () => { utils?.shareURL(`${process.env.REACT_APP_STARTAPP}?startapp=${teleUser?.id}`, `Spin the wheel & earn more money!`); } }
                        >
                          <img src={tele} className="w-[16px]" />
                          <span>Share invite link</span>
                      </button>
                  </div>
                </div>
            </Modal>
        </Content>
      </Layout>
    </Layout>
  );
};
export default TemplateLayout;

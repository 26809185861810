import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getHistory } from "../../web3/app";
import { getAvatar } from '../../web3/ultils';
import dayjs from "dayjs";
import { Table } from "antd";

import tonLogo from "../../assets/img/ton.png";

export default function History() {
    const navigate = useNavigate();
    const [historyData, setHistoryData] = useState<any>();
    
    useEffect(() => {
        csGetHistory();
    }, []);


    const csGetHistory = async () => {
        const round = await getHistory(`drawn`);
        if(round){
            setHistoryData(round);
        }
    }

    const historyColumns = [
        {
            title: "Round",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Winner",
            dataIndex: "tele_username",
            key: "tele_username",
            render: (_, record) => {
                return (
                    <div className="flex gap-5 items-center">
                        <img className="w-[36px] h-[36px] rounded-md" src={ record && record.tele_username ? getAvatar(record.tele_username) : ``} />
                        <span>{record?.tele_username}</span>
                    </div>
                );
            }
        },
        {
            title: "Prize Pool",
            dataIndex: "total_amount",
            key: "total_amount",
            render: (_, record) => {
                return (
                    <div className="flex gap-2 items-center">
                        <span className="font-bold text-[16px]">{record.total_amount}</span>
                        <img className="w-[20px]" src={tonLogo} />
                    </div>
                )
            }
        },
        {
            title: "Winner's Entries",
            dataIndex: "winner_amount",
            key: "winner_amount",
            render: (_, record) => {
                return (
                    <div className="flex gap-2 items-center">
                        <span className="font-bold text-[16px]">{record.winner_amount}</span>
                        <img className="w-[20px]" src={tonLogo} />
                        <span className="text-[#91abc9] text-xs">{(record.winner_amount / record.total_amount * 100).toFixed(2)}%</span>
                    </div>
                )
            },
        },
        {
            title: "Players",
            dataIndex: "total_player",
            key: "total_player",
        },
        {
            title: "Finish",
            dataIndex: "drawn_at",
            key: "drawn_at",
            render: (_, record) => {
                return dayjs(record.drawn_at*1000).utc().format("DD MMM YYYY, HH:mm");
            },
        }
    ];

    return(
        <>
            {
                historyData &&
                <Table
                    className="w-full table-rewards"
                    dataSource={historyData }
                    columns={historyColumns}
                    scroll={{ x: "max-content" }}
                    pagination={{
                        defaultPageSize: 10,
                        total: historyData.length,
                        position: ["bottomCenter"],
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                          onClick: () => {navigate(`/round/${record.id}`, { replace: false });}
                        };
                    }}
                />
            }
        </>
    );
}
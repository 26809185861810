import { useEffect, useState, useRef } from "react";
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import { Modal } from "antd";
import axios from "axios";
import ReactLoading from "react-loading";

import { io } from "socket.io-client";

import { NavLink, useLocation } from "react-router-dom";

import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';

import Countdown, { zeroPad } from "react-countdown";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { truncateString, abbreviateNumber, maxdept, mindept, getColor, getAvatar } from "../../web3/ultils";
import { getUser, addFund, getCurrentRound, getRoundDetail } from '../../web3/app';
import { ViewportList } from "react-viewport-list";

import toast from "react-hot-toast";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { StepForwardOutlined, ArrowLeftOutlined, ArrowRightOutlined, HistoryOutlined } from '@ant-design/icons';
import logoTon from "../../assets/img/ton.png";
import win from "../../assets/img/win.png";

dayjs.extend(utc);
ChartJS.register(ArcElement, Tooltip);

const currency = process.env.REACT_APP_CURRENCY;

const socket = io(process.env.REACT_APP_API);

export default function Home() { 

    // var now = dayjs();
    // var drawnAt = now.add(120, `second`);
    // console.log(`now`, now.format('YYYY-MM-DD HH:mm:ss'));
    // console.log(`drawAt`, drawnAt.format('YYYY-MM-DD HH:mm:ss'));

    const { initDataRaw } = retrieveLaunchParams();
    // let initDataRaw;
    let teleUser;
    teleUser = {
        id: `740772070`,
        username: `judasle7`,
        firstName: `Judas`,
        lastName: `L`
    };

    const initData = useInitData();
    teleUser = initData.user;

    const [userData, setUserData] = useState<any>();
    
    const [amountInput, setAmountInput] = useState(mindept);
    const [roundsInput, setRoundsInput] = useState(1);
    const [totalEntries, setTotalEntries] = useState(amountInput*roundsInput);
  
    const [chartData, setChartData] = useState<any>();
    const [chartOptions, setChartOptions] = useState<any>();
    
    const [players, setPlayers] = useState([]);
    const [player, setPlayer] = useState<any>();
  
    const [rotate, setRotate] = useState(0);
    const [isDrawing, setIsDrawing] = useState(false);
    const [isNextRound, setIsNextRound] = useState(false);
    const [enabledAddFun, setEnabledAddFun] = useState(false);
    const [nextRoundTimeRemain, setNextRoundTimeRemain] = useState(20);
    
  
    const [selectedBtnId, setSelectedBtnId] = useState();
    const [btnLoading, setBtnLoading] = useState(false);
  
    const addSpinner = (btnId) => {
      setBtnLoading(true);
      setSelectedBtnId(btnId);
    };
    const removeSpinner = (btnId) => {
      setBtnLoading(false);
      setSelectedBtnId(null);
    };

    const [currentRoundInfo, setCurrentRoundInfo] = useState<any>();

    const [isFunInModalOpen, setFunInModalOpen] = useState(false);
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const handleCancelFunInModal = () => {
        setFunInModalOpen(false);
    };
    const handleCancelUserModal = () => {
        setIsUserModalOpen(false);
    };
    const handleOpenUserModal = async (userInfo) => {
        setPlayer(userInfo);
        setIsUserModalOpen(true);
    };

    const ref = useRef(null);

    const handleMaxBtn = async () => {
        await fetchUser();
        if(maxdept <= userData.ton){
          setAmountInput(maxdept);
        }else{
          setAmountInput(userData.ton);
        }
    };

    const fetchUser = async () => {
        const user = await getUser(teleUser.id.toString());
        if(user){ setUserData(user) }
    };

    const handleAddFund = async () => {
        await fetchUser();
        if(amountInput > userData.ton){ 
          toast.error(`Insufficient balance.`); return;
        }
        if(amountInput < mindept || amountInput > maxdept){
          toast.error(`Amount must be between ${mindept} TON and ${maxdept} TON.`); return;
        }
        const btnID = "deposit";
        addSpinner(btnID);
        const deposit = await addFund(userData.id, amountInput, currentRoundInfo.id, initDataRaw);
        if (deposit.status == "success") {
          removeSpinner(btnID);
          toast.success(deposit.message);
          await csGetCurrentRound();
          await fetchUser();
          setFunInModalOpen(false);
        } else {
          removeSpinner(btnID);
          toast.error(deposit.message);
        }
    };

    useEffect(() => {
        fetchUser();
        getTonUsd();
	}, []);



    useEffect(() => {
        csGetCurrentRound();

        socket.on('addFund', (arg) => {
            console.log(`addFund event`, arg)
            csGetCurrentRound();
        });
    
        socket.on('draw', (arg) => {
            console.log(`draw event`, arg)
            draw(arg);
        });

	}, [userData]);


    const draw = async (round) => {
        setEnabledAddFun(false);
        if(currentRoundInfo && round.id == currentRoundInfo.id){
            
            csGetRoundDetail();
            setTimeout(
                () => {
                    csGetCurrentRound()
                },
                20000
            );

            if(round.status == 'drawn'){
                setIsDrawing(true);
                // setup winner
                let degMax = 0;
                let winnerObj = players.find(x => x.user_id === round.winner);
                let winnerIndex = players.indexOf(winnerObj);
                players.slice(0, winnerIndex+1).map((item) => {
                    let currentDeg = 360*item.winchance/100;
                    degMax += currentDeg;
                });
                let degMin = degMax - 360*players[winnerIndex].winchance/100;
                setTimeout(() => {
                    const rotateDeg = Math.random() * (degMax - degMin) + degMin;
                    const rotateValue = (20*360 - rotateDeg);
                    setRotate(rotateValue);
                }, 1000);

                setTimeout(
                    () => {
                        setIsDrawing(false);
                        setIsNextRound(true);
                    },
                    10000
                );
            }else{
                // cancelled
                setIsNextRound(true);
            }

            let nextRoundCount = 30;
            let nextTimer = setInterval(function(){
                if(nextRoundCount <= 0){
                    clearInterval(nextTimer);
                }
                setNextRoundTimeRemain(nextRoundCount);
                nextRoundCount -= 1;
            }, 1000);
        }
    }

    const csGetCurrentRound = async () => {
        const round = await getCurrentRound(userData?.id);
        setData(round);

        setRotate(0);
        setIsDrawing(false);
        setIsNextRound(false);
        setEnabledAddFun(true)
        setNextRoundTimeRemain(20);
    }

    const csGetRoundDetail = async () => {
        const round = await getRoundDetail(currentRoundInfo?.id, userData?.id);
        setData(round);
    }

    const [tonUsd, setTonUsd] = useState(0);
    const getTonUsd = async () => {
        fetch("https://api.binance.com/api/v3/ticker/price?symbol=TONUSDT")
        .then((response) => response.json())
        .then((jsonData) => {
            setTonUsd(jsonData.price);
        })
        .catch((error) => {
            console.error(error);
        });
    };

    const setData = async (round) => {
        let depositer = [];
        let winnerInfo = {};
        if(round){
          if(round.deposits.length > 0){
            round.deposits.map((item, index) => {
              depositer.push({
                user_id: item.user_id,
                wallet: item.tele_username,
                winchance: (item.amount/round.total_amount * 100).toFixed(2),
                amount: item.amount,
                avatar: getAvatar(item.tele_username),
                points: item.point,
                color: getColor(index),
                is_win: round.winner == item.user_id ? true : false,
                played: item.played,
                won: item.won,
                biggest_win: item.biggest_win,
                luckiest_win: `-`
              });
              if(round.winner == item.user_id){
                winnerInfo = {
                    user_id: item.user_id,
                    name: item.tele_username
                }
              }
            });
          }
        }
        setPlayers(depositer);
        setCurrentRoundInfo({...round, winnerInfo});
    
        const chartLabels = [];
        const chartNumber = [];
        const chartBg = [];
        depositer.map((item) => {
          chartLabels.push(truncateString(item.wallet, 4, 4));
          chartNumber.push(item.amount);
          chartBg.push(`#${item.color}`);
        });
    
        setChartData({
          labels: chartLabels,
          datasets: [
            {
              label: "",
              data: chartNumber,
              backgroundColor: chartBg,
              borderWidth: 0,
              hoverOffset: 5,
              cutout: 117,
              rotation: 0,
              animations: false,
            }
          ],
        });
    
        setChartOptions(
          {
            plugins: {
              tooltip: {
                enabled: false,
                external: function(context) {
                  document.querySelectorAll(".player-item").forEach(el => el.classList.remove("hover"));
                  const tooltipModel = context.tooltip;
                  const el = document.querySelectorAll(`.player-item[data-index='${tooltipModel.dataPoints[0].dataIndex}']`);
                    if(el.length){
                        el[0].classList.add("hover");
                    }
                }
              }
            },
            onClick: (e) => {}
          }
        )
      };


    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          return <>
                <button className="button is-outline w-[90px]">
                    ...
                </button>
            </>;
        } else {
          return (
            <button className="button is-outline w-[90px]">
                {zeroPad(minutes)}:{zeroPad(seconds)}
            </button>
          );
        }
    };  

    // console.log(`roundinfo`, currentRoundInfo);
      console.log(`players`, players);

    return(
        <div className="container-fluid mx-auto py-0 game">
            <div className="flex flex-col lg:grid grid-cols-4 grid-rows-2 gap-4">
                <div className="row-span-4">
                    <div className="spin-box h-full relative">
                        <div className="spin-box-head flex justify-between mb-5 items-center">
                        	<h3 className="font-bold text-lg">{players?.length} Players</h3>
                        </div>
                        <div className="players-list" ref={ref}>
                        {
                            players &&
                            <ViewportList
                                viewportRef={ref}
                                items={players}
                                >
                                {(item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleOpenUserModal(item)}
                                        data-index={index}
                                        className={`player-item flex justify-between items-center gap-3 rounded-xl mb-2 p-3 color-${item.color} ${item.is_won && 'win'}`}
                                        >
                                        <img className="w-[40px] h-[40px] rounded-full bg-[#001529]" src={item.avatar}/>
                                        <div className="wallet flex-1">
                                            <div className=" mb-1 font-bold flex align-center gap-1">
                                            {item.wallet}
                                            {
                                                item.is_won && <img className="w-[20px] h-[20px]" src={win} />
                                            }
                                            </div>
                                            <span>{abbreviateNumber(item.points)} Pts</span>
                                        </div>
                                        <div className="amount text-right">
                                            <div className="winchance mb-1 font-bold">{item.winchance}%</div>
                                            <div className="flex items-center gap-1">
                                            {item.amount}
                                            <img  className="w-[18px] h-[18px]" src={logoTon} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ViewportList>
                        }
                        </div>
                        <div id="chartjs-tooltip"></div>
                    </div>
                </div>
                <div className="col-span-2 row-span-4 order-first lg:order-none">
					<div className="spin-box h-full">
						
						<div className="m-auto max-w-[460px]">
                            <div className="relative spinner-relative">
                                <div className="spin-box-head flex justify-between items-center mb-10">
                                    <div className="toolbar">
                                        <NavLink to="/history" className="flex items-center gap-2 px-3 text-sm py-[6px] rounded-[12px] border-[#735893] border uppercase">
                                            <HistoryOutlined /> History
                                        </NavLink>
                                    </div>
                                    <div className="slick-control flex gap-1">
                                        <button className="button is-outline"
                                        onClick={() => {}}
                                        ><ArrowLeftOutlined /></button>
                                        <button className="button is-outline"
                                        onClick={() => {}}
                                        ><ArrowRightOutlined /></button>
                                        <NavLink to="/game" className="button is-outline" ><StepForwardOutlined /></NavLink>
                                    </div>
                                </div>
                                <div className="spinner-wrp">
                                    <div className="spinner">
                                        <div className="spinner-inner">
                                        {
                                            chartData && 
                                            <div className="spinwrp">
                                                <div className="spinarrow"></div>
                                                <div className="spincontent">
                                                    { currentRoundInfo && currentRoundInfo.status == 'open' &&
                                                        <div className="inner deposit">
                                                            <div className="flex items-center text-[32px] font-bold gap-2">
                                                            <img className="w-[32px] h-[32px]" src={logoTon} />
                                                            <span>{currentRoundInfo.total_amount}</span>
                                                            </div>
                                                            <div className="flex items-center justify-center text-[16px] gap-2">
                                                            <span>${(currentRoundInfo.total_amount * tonUsd).toLocaleString('en-US')}</span>
                                                            </div>
                                                        </div>
                                                    }
                                                    { currentRoundInfo && currentRoundInfo.status == 'drawn' &&
                                                        (
                                                            isDrawing ? 
                                                            <div className="inner drawing">
                                                                <div className="flex items-center text-[46px] font-bold gap-2">
                                                                    <img className="w-[50px] h-[50px]" src={logoTon} />
                                                                    <span>{currentRoundInfo.total_amount}</span>
                                                                </div>
                                                                <div className="text-center font-bold">Drawing Winner...</div>
                                                            </div>
                                                            :   
                                                            <div className="inner winner text-center">
                                                                <img className="w-[100px] h-[100px] m-auto" src={win} />
                                                                <div className="font-bold text-primary-gradient text-[24px] mb-1">@{currentRoundInfo.winnerInfo.name}</div>
                                                                <div className="flex items-center  justify-center gap-2 font-bold">
                                                                    <div className="flex items-center gap-1">
                                                                        <img className="w-[20px] h-[20px]" src={logoTon} />
                                                                        <span>{currentRoundInfo.total_amount}</span>
                                                                    </div>
                                                                    {/* <span>•</span>
                                                                    <div className="flex items-center gap-1">
                                                                        <span>1.5x WIN</span>
                                                                    </div> */}
                                                                </div>
                                                                { isNextRound &&
                                                                    <div className="text-center font-bold">Next round: {nextRoundTimeRemain}s</div>
                                                                }
                                                                
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        currentRoundInfo && currentRoundInfo.status == 'cancelled' &&
                                                            <div className="inner cancelled">
                                                                <div className="flex items-center justify-center text-[18px] gap-2">
                                                                    Not Enough Players
                                                                </div>
                                                                { isNextRound &&
                                                                    <div className="text-center font-bold">Next round: {nextRoundTimeRemain}s</div>
                                                                }
                                                            </div>
                                                    }

                                                </div>
                                                <div className="spinchart spin" style={{
                                                transform: `rotate(${rotate}deg)`
                                                }}>
                                                <Doughnut data={chartData} options={chartOptions} />
                                                </div>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
                </div>
                <div className="round-info">
					<div className="spin-box bg-[#1c0d34] border-[#735893] p-3 pb-5 pt-4  h-full">
						<div className="spin-box-head flex justify-between mb-2 items-center">
						<h3 className="font-bold text-lg">Round {currentRoundInfo?.id}</h3>
						<div className="toolbar">
                            {
                                currentRoundInfo && currentRoundInfo.start_at > 0 &&
                                    <div className="countdown">
                                        <Countdown
                                            date={currentRoundInfo.drawn_at * 1000}
                                            renderer={renderer}
                                        ></Countdown>
                                    </div>
                            }
                            {
                                currentRoundInfo && currentRoundInfo.total_amount == 0 &&
                                    <div className="countdown">
                                        <button className="button is-outline">
                                            Ready
                                        </button>
                                    </div>
                            }
						</div>
						</div>
						<div className="">
						<div className="grid grid-cols-3 lg:grid-cols-2 gap-0 lg:gap-4">
							<div className="block text-sm text-[#91abc9]">
							<div className="flex items-center gap-2 text-xl font-bold lg:mb-1 text-[#ffffff]">
								<img  className="w-[24px] h-[24px]" src={logoTon} />
								{currentRoundInfo?.total_amount?.toLocaleString('en-US')}
							</div>
							<p className="text-xs">Prize Pool</p>
							</div>
							<div className="block text-sm text-[#91abc9] hidden lg:block">
							<div className="flex items-center text-xl font-bold lg:mb-1">
								<span className="text-[#ffffff]">{players?.length}</span>/500
							</div>
							<p className="text-xs">Players</p>
							</div>
							<div className="block text-sm text-[#91abc9]">
							<div className="flex items-center gap-2 text-xl font-bold lg:mb-1">
								<img  className="w-[24px] h-[24px]" src={logoTon} />
								{ currentRoundInfo?.playerInfo?.amount}
							</div>
							<p className="text-xs">Your entries</p>
							</div>
							<div className="block text-sm text-[#91abc9]">
							<div className="flex items-center gap-2 text-xl font-bold lg:mb-1">
								{ currentRoundInfo?.total_amount && (currentRoundInfo?.playerInfo?.amount / currentRoundInfo?.total_amount * 100).toFixed(2) }%
							</div>
							<p className="text-xs">Your Win Chance</p>
							</div>
						</div>
						</div>
						<button
						onClick={() => setFunInModalOpen(true)}
						className="button bg-primary-gradient flex justify-center items-center gap-2 w-full mt-2">Enter now</button>
					</div>
                </div>
                
            </div>
            <Modal
                forceRender
                open={isFunInModalOpen}
                onCancel={handleCancelFunInModal}
                footer={false}
                className="modal-create-token max-w-[600px]"
            >
                <div className="text-white">
                <div>
                    <div className="mb-5">
                    <h4 className="font-medium">{currency} entry per round</h4>
                    <div className="relative mt-2">
                        <input
                        type="number"
                        placeholder=""
                        className="w-full py-3 px-3 rounded-[12px] bg-[#271B40]"
                        value={amountInput}
                        onChange={(e) => setAmountInput(parseInt(e?.target?.value))}
                        />
                    </div>
                    {
                        (amountInput > userData?.ton) &&
                        <span className="error">Insufficient balance</span>
                    }
                    <div className="handleAmount flex gap-2 mt-4">
                        <button className="flex-1 flex items-center justify-center gap-1 px-3 text-sm py-1.5 rounded-[12px] bg-[#432D5C]"
                            onClick={() => setAmountInput(1)} >
                            1 <img  className="w-[14px] h-[14px]" src={logoTon} />
                        </button>
                        <button className="flex-1 flex items-center justify-center gap-1 px-3 text-sm py-1.5 rounded-[12px] bg-[#432D5C]"
                            onClick={() => setAmountInput(10)}>
                            10 <img  className="w-[14px] h-[14px]" src={logoTon} />
                        </button>
                        <button className="flex-1 flex items-center justify-center gap-1 px-3 text-sm py-1.5 rounded-[12px] bg-[#432D5C]"
                            onClick={() => setAmountInput(100)}>
                            100 <img  className="w-[14px] h-[14px]" src={logoTon} />
                        </button>
                        <button className="flex-1 flex items-center justify-center gap-1 px-3 text-sm py-1.5 rounded-[12px] bg-[#432D5C]"
                            onClick={handleMaxBtn}>
                            MAX
                        </button>
                    </div>
                    </div>
                    <div className="mb-5">
                    <h4 className="font-medium">Number of Rounds</h4>
                    <div className="relative mt-2">
                        <input
                        type="number"
                        disabled
                        step={1}
                        className="w-full py-3 px-3 rounded-[12px] bg-[#271B40] opacity-50"
                        value={roundsInput}
                        onChange={(e) => setRoundsInput(parseInt(e?.target?.value))}
                        />
                    </div>
                    </div>
                    <div className="mb-5 flex justify-between items-center">
                    <h4 className="font-medium">TON in account</h4>
                    <div className="flex items-center gap-2">
                        <span className="text-xs text-[#91abc9]">${(userData?.ton*tonUsd).toLocaleString("en-US")}</span>
                        <div className="flex items-center gap-2 font-bold">
                        {userData?.ton.toLocaleString("en-US")} TON 
                        <img  className="w-[20px] h-[20px]" src={logoTon} />
                        </div>
                    </div>
                    </div>
                    <div className="flex justify-between items-center">
                    <h4 className="font-medium">Total Entry</h4>
                    <div className="flex items-center gap-2">
                        <span className="text-xs text-[#91abc9]">${(amountInput*roundsInput*tonUsd).toLocaleString("en-US")}</span>
                        <div className="flex items-center gap-2 font-bold">
                        {(amountInput*roundsInput).toLocaleString("en-US")} {currency} 
                        <img  className="w-[20px] h-[20px]" src={logoTon} />
                        </div>
                    </div>
                    </div>
                    {
                    (amountInput*roundsInput > userData?.ton) &&
                        <span className="error text-right">Insufficient balance</span>
                    }
                    <button 
                        onClick={() => handleAddFund()}
                        className="button bg-primary-gradient flex justify-center items-center gap-2 w-full mt-5">
                        Add Funds
                   </button>
                </div>
                </div>
            </Modal>

            <Modal
                forceRender
                open={isUserModalOpen}
                onCancel={handleCancelUserModal}
                footer={false}
                className="modal-user max-w-[600px]"
            >
                <div className="text-white">
                <div className="profile text-center mt-5">
                    <img className="w-[70px] h-[70px] m-auto rounded-full bg-[#001529]" src={player?.avatar}/>
                    <div className="font-bold text-secondary-gradient text-[24px] mb-1 mt-3">{player && player?.wallet}</div>
                </div>
                <div className="user-past-rounds mt-5">
                    <div className="flex items-center justify-between">
                    <h3 className="font-bold mb-2">Past Rounds</h3>
                    {/* <NavLink target="_blank" to={`https://solscan.io/address/${player?.wallet}?cluster=devnet`} className="flex items-center gap-2 text-[#fff] " >View wallet</NavLink> */}
                    </div>

                    <div className="flex flex-wrap lg:flex-nowrap justify-between text-center rounded-xl border-[#ffffff29] border-[1px] mt-2">
                    <div className="p-5 border-[#ffffff29] border-b-[1px] lg:border-b-[0] border-r-[1px] w-[50%] lg:w-[25%]">
                        <h4 className="font-bold text-lg">{player?.played}</h4>
                        <span className="text-xs text-[#91abc9]">Played</span>
                    </div>
                    <div className="p-5 border-[#ffffff29] border-b-[1px] lg:border-b-[0] lg:border-r-[1px] w-[50%] lg:w-[25%]">
                        <h4 className="font-bold text-lg">{player?.won}</h4>
                        <span className="text-xs text-[#91abc9]">Won</span>
                    </div>
                    <div className="p-5 border-[#ffffff29]  border-r-[1px] w-[50%] lg:w-[25%]">
                        <h4 className="font-bold text-lg">{player?.biggest_win}</h4>
                        <span className="text-xs text-[#91abc9]">Biggest Win</span>
                    </div>
                    <div className="p-5 w-[50%] lg:w-[25%]">
                        <h4 className="font-bold text-lg">{player?.luckiest_win}</h4>
                        <span className="text-xs text-[#91abc9]">Luckiest Win</span>
                    </div>
                    </div>
                </div>
                <div className="user-this-rounds mt-5">
                    <div className="flex items-center justify-between mt-6 mb-2">
                    <h3 className="font-bold">This Rounds</h3>
                    </div>
                    <div className="flex justify-between p-5 rounded-xl bg-[#202039]">
                    <div>
                        <div className="flex items-center gap-1 text-lg font-bold">
                        <img  className="w-[24px] h-[24px]" src={logoTon} />
                        {player?.amount}
                        </div>
                        <span className="text-xs text-[#91abc9]">Entries’ Value</span>
                    </div>
                    <div>
                        <div className="text-lg font-bold">
                        {player?.winchance}%
                        </div>
                        <span className="text-xs text-[#91abc9]">Win Chance</span>
                    </div>
                    <div>
                        <div className="text-lg font-bold">
                        {abbreviateNumber(player?.points)} Pts
                        </div>
                        <span className="text-xs text-[#91abc9]">Pts</span>
                    </div>
                    </div>
                </div>
                </div>
            </Modal>

            </div>
    );
}
import { useEffect, useState, useRef } from "react";
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import { Modal } from "antd";
import axios from "axios";
import ReactLoading from "react-loading";

import { useParams } from "react-router-dom";

import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { truncateString, abbreviateNumber, maxdept, mindept, getColor, getAvatar } from "../../web3/ultils";
import { getUser, getRoundDetail } from '../../web3/app';
import { ViewportList } from "react-viewport-list";

import toast from "react-hot-toast";

import randomItem from 'random-item-percent';

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { HistoryOutlined, ArrowLeftOutlined, ArrowRightOutlined, StepForwardOutlined } from '@ant-design/icons';
import logoTon from "../../assets/img/ton.png";
import win from "../../assets/img/win.png";
import { NavLink } from "react-router-dom";

dayjs.extend(utc);
ChartJS.register(ArcElement, Tooltip);

const currency = process.env.REACT_APP_CURRENCY;

export default function RoundDetail() {

    const { roundId } = useParams();

    // var now = dayjs();
    // var drawnAt = now.add(120, `second`);
    // console.log(`now`, now.format('YYYY-MM-DD HH:mm:ss'));
    // console.log(`drawAt`, drawnAt.format('YYYY-MM-DD HH:mm:ss'));

    let teleUser;
    teleUser = {
        id: `740772070`,
        username: `judasle7`,
        firstName: `Judas`,
        lastName: `L`
    };

    const initData = useInitData();
    teleUser = initData.user;

    const [userData, setUserData] = useState<any>();

    const [chartData, setChartData] = useState<any>();
    const [chartOptions, setChartOptions] = useState<any>();
    
    const [players, setPlayers] = useState([]);
    const [player, setPlayer] = useState<any>();
  
    const [rotate, setRotate] = useState(0);

    const [currentRoundInfo, setCurrentRoundInfo] = useState<any>();

    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    
    const handleCancelUserModal = () => {
        setIsUserModalOpen(false);
    };
    const handleOpenUserModal = async (userInfo) => {
        setPlayer(userInfo);
        setIsUserModalOpen(true);
    };

    const ref = useRef(null);

    const fetchUser = async () => {
        const user = await getUser(teleUser.id.toString());
        if(user){ setUserData(user) }
    };

    useEffect(() => {
        fetchUser();
        getTonUsd();
        
	}, []);

    useEffect(() => {
        csGetCurrentRound();
	}, [userData]);

    const csGetCurrentRound = async () => {
        const round = await getRoundDetail(parseInt(roundId), userData?.id);
        setData(round);
        if(players){
            // setup winner
            let degMax = 0;
            let winnerObj = players.find(x => x.user_id === round.winner);
            let winnerIndex = players.indexOf(winnerObj);
            players.slice(0, winnerIndex+1).map((item) => {
                let currentDeg = 360*item.winchance/100;
                degMax += currentDeg;
            });
            let degMin = degMax - 360*players[winnerIndex]?.winchance/100;
            const rotateDeg = (degMax + degMin) / 2;
            const rotateValue = (360 - rotateDeg);
            setRotate(rotateValue);
        }
    }

    const [tonUsd, setTonUsd] = useState(0);
    const getTonUsd = async () => {
        fetch("https://api.binance.com/api/v3/ticker/price?symbol=TONUSDT")
        .then((response) => response.json())
        .then((jsonData) => {
            setTonUsd(jsonData.price);
        })
        .catch((error) => {
            console.error(error);
        });
    };

    const setData = async (round) => {
        let depositer = [];
        let winnerInfo;
        if(round){
          if(round.deposits.length > 0){
            round.deposits.map((item, index) => {
              depositer.push({
                user_id: item.user_id,
                wallet: item.tele_username,
                winchance: (item.amount/round.total_amount * 100).toFixed(2),
                amount: item.amount,
                avatar: getAvatar(item.tele_username),
                points: item.point,
                color: getColor(index),
                is_win: round.winner == item.user_id ? true : false,
                played: item.played,
                won: item.won,
                biggest_win: item.biggest_win,
                luckiest_win: `-`
              });

              if(round.winner == item.user_id){
                winnerInfo = {
                    user_id: item.user_id,
                    name: item.tele_username
                }
              }
            });
          }
        }
        setPlayers(depositer);
        setCurrentRoundInfo({...round, winnerInfo});
    
        const chartLabels = [];
        const chartNumber = [];
        const chartBg = [];
        depositer.map((item) => {
          chartLabels.push(truncateString(item.wallet, 4, 4));
          chartNumber.push(item.amount);
          chartBg.push(`#${item.color}`);
        });
    
        setChartData({
          labels: chartLabels,
          datasets: [
            {
              label: "",
              data: chartNumber,
              backgroundColor: chartBg,
              borderWidth: 0,
              hoverOffset: 5,
              cutout: 117,
              rotation: 0,
              animations: false,
            }
          ],
        });
    
        setChartOptions(
          {
            plugins: {
              tooltip: {
                enabled: false,
                external: function(context) {
                  document.querySelectorAll(".player-item").forEach(el => el.classList.remove("hover"));
                  const tooltipModel = context.tooltip;
                  const el = document.querySelectorAll(`.player-item[data-index='${tooltipModel.dataPoints[0].dataIndex}']`);
                    if(el.length){
                        el[0].classList.add("hover");
                    }
                }
              }
            },
            onClick: (e) => {}
          }
        )
      };

      
    return(
        <div className="container-fluid mx-auto py-0 ">
            <div className="flex flex-col lg:grid grid-cols-4 grid-rows-2 gap-4">
                <div className="row-span-4">
                    <div className="spin-box bg-[#18182b] rounded-xl p-4 h-full relative">
                        <div className="spin-box-head flex justify-between mb-5 items-center">
                        	<h3 className="font-bold text-lg">{players?.length} Players</h3>
                        </div>
                        <div className="players-list" ref={ref}>
                        {
                            players &&
                            <ViewportList
                                viewportRef={ref}
                                items={players}
                                >
                                {(item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleOpenUserModal(item)}
                                        data-index={index}
                                        className={`player-item flex justify-between items-center gap-3 rounded-xl mb-2 p-3 color-${item.color} ${item.is_win && 'win'}`}
                                        >
                                        <img className="w-[40px] h-[40px] rounded-md bg-[#001529]" src={item.avatar}/>
                                        <div className="wallet flex-1">
                                            <div className=" mb-1 font-bold flex align-center gap-1">
                                            {item.wallet}
                                            {
                                                item.is_win && <img className="w-[20px] h-[20px]" src={win} />
                                            }
                                            </div>
                                            <span>{abbreviateNumber(item.points)} Pts</span>
                                        </div>
                                        <div className="amount text-right">
                                            <div className="winchance mb-1 font-bold">{item.winchance}%</div>
                                            <div className="flex items-center gap-1">
                                            {item.amount}
                                            <img  className="w-[18px] h-[18px]" src={logoTon} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ViewportList>
                        }
                        </div>
                        <div id="chartjs-tooltip"></div>
                    </div>
                </div>
                <div className="col-span-2 row-span-4 order-first lg:order-none">
					<div className="spin-box h-full">
						<div className=" m-auto max-w-[460px]">
                            <div className="relative spinner-relative">
                                <div className="spin-box-head flex justify-between items-center mb-10">
                                    <div className="toolbar">
                                        <NavLink to="/history" className="flex items-center gap-2 px-3 text-sm py-[6px] rounded-[12px] border-[#735893] border uppercase">
                                            <HistoryOutlined /> History
                                        </NavLink>
                                    </div>
                                    <div className="slick-control flex gap-1">
                                        <button className="button is-outline"
                                        onClick={() => {}}
                                        ><ArrowLeftOutlined /></button>
                                        <button className="button is-outline"
                                        onClick={() => {}}
                                        ><ArrowRightOutlined /></button>
                                        <NavLink to="/game" className="button is-outline" ><StepForwardOutlined /></NavLink>
                                    </div>
                                </div>
                                <div className="spinner-wrp">
                                    <div className="spinner">
                                        <div className="spinner-inner">
                                        {
                                            chartData && 
                                            <div className="spinwrp">
                                                <div className="spinarrow"></div>
                                                <div className="spincontent bg-[#050518] rounded-full">
                                                    {
                                                        currentRoundInfo && currentRoundInfo.status == 'cancelled' &&
                                                            <div className="inner cancelled">
                                                                <div className="flex items-center justify-center text-[18px] gap-2">
                                                                    Not Enough Players
                                                                </div>
                                                            </div>
                                                    }
                                                    {
                                                        currentRoundInfo && currentRoundInfo.status == 'drawn' &&
                                                            <div className="inner winner text-center">
                                                                <img className="w-[100px] h-[100px] m-auto" src={win} />
                                                                <div className="font-bold text-primary-gradient text-[24px] mb-1">@{currentRoundInfo.winnerInfo.name}</div>
                                                                <div className="flex items-center  justify-center gap-2 font-bold">
                                                                <div className="flex items-center gap-1">
                                                                    <img className="w-[20px] h-[20px]" src={logoTon} />
                                                                    <span>{currentRoundInfo.total_amount}</span>
                                                                </div>
                                                                {/* <span>•</span>
                                                                <div className="flex items-center gap-1">
                                                                    <span>1.5x WIN</span>
                                                                </div> */}
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                                <div className="spinchart" style={{
                                                    transform: `rotate(${rotate}deg)`
                                                }}>
                                                <Doughnut data={chartData} options={chartOptions} />
                                                </div>
                                            </div>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>

							
						</div>
					</div>
                </div>
                <div className="round-info">
					<div className="spin-box bg-[#000] lg:bg-[#18182b] rounded-xl p-4 pb-5 pt-2 lg:p-4 h-full">
						<div className="spin-box-head flex justify-between mb-2 items-center">
						<h3 className="font-bold text-lg">Round {currentRoundInfo?.id}</h3>
						<div className="toolbar">
                            {
                                currentRoundInfo && currentRoundInfo.status == 'cancelled' &&
                                <button className="uppercase flex items-center justify-center gap-2 px-3 py-1 rounded-lg border-[#ff4d4f] text-xs text-[#ff4d4f] border w-[90px]">
                                    {currentRoundInfo.status}
                                </button>
                            }
                        </div>
						</div>
						<div className="">
						<div className="grid grid-cols-3 lg:grid-cols-2 gap-0 lg:gap-4">
							<div className="block text-sm text-[#91abc9]">
							<div className="flex items-center gap-2 text-xl font-bold lg:mb-1 text-[#ffffff]">
								<img  className="w-[24px] h-[24px]" src={logoTon} />
								{currentRoundInfo?.total_amount?.toLocaleString('en-US')}
							</div>
							<p className="text-xs">Prize Pool</p>
							</div>
							<div className="block text-sm text-[#91abc9] hidden lg:block">
							<div className="flex items-center text-xl font-bold lg:mb-1">
								<span className="text-[#ffffff]">{players?.length}</span>/500
							</div>
							<p className="text-xs">Players</p>
							</div>
							<div className="block text-sm text-[#91abc9]">
							<div className="flex items-center gap-2 text-xl font-bold lg:mb-1">
								<img  className="w-[24px] h-[24px]" src={logoTon} />
								{ currentRoundInfo?.playerInfo?.amount}
							</div>
							<p className="text-xs">Your entries</p>
							</div>
							<div className="block text-sm text-[#91abc9]">
							<div className="flex items-center gap-2 text-xl font-bold lg:mb-1">
								{ currentRoundInfo?.total_amount && (currentRoundInfo?.playerInfo?.amount / currentRoundInfo?.total_amount * 100).toFixed(2) }%
							</div>
							<p className="text-xs">Your Win Chance</p>
							</div>
						</div>
						</div>

                        <NavLink to="/"
                            className="lg:hidden px-3 lg:px-4 text-sm py-3 lg:py-3 rounded-lg text-white bg-primary-gradient flex justify-center items-center gap-2 w-full mt-2"
                        >
                            Back to Current Round
                        </NavLink>
						
					</div>
                </div>
                <div className="row-span-3 row-start-2 col-start-4">
                    <div className="bg-[#18182b] rounded-xl p-4 h-full relative hidden lg:block">
                        <div>
                            <NavLink to="/"
                                className="px-3 lg:px-4 text-sm py-3 lg:py-3 rounded-lg text-white bg-primary-gradient flex justify-center items-center gap-2 w-full mt-2"
                            >
                                Back to Current Round
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            
            <Modal
                forceRender
                open={isUserModalOpen}
                onCancel={handleCancelUserModal}
                footer={false}
                className="modal-user max-w-[600px]"
            >
                <div className="text-white">
                <div className="profile text-center mt-5">
                    <img className="w-[70px] h-[70px] m-auto rounded-full bg-[#001529]" src={player?.avatar}/>
                    <div className="font-bold text-secondary-gradient text-[24px] mb-1 mt-3">{player && player?.wallet}</div>
                </div>
                <div className="user-past-rounds mt-5">
                    <div className="flex items-center justify-between">
                    <h3 className="font-bold mb-2">Past Rounds</h3>
                    {/* <NavLink target="_blank" to={`https://solscan.io/address/${player?.wallet}?cluster=devnet`} className="flex items-center gap-2 text-[#fff] " >View wallet</NavLink> */}
                    </div>

                    <div className="flex flex-wrap lg:flex-nowrap justify-between text-center rounded-xl border-[#ffffff29] border-[1px] mt-2">
                    <div className="p-5 border-[#ffffff29] border-b-[1px] lg:border-b-[0] border-r-[1px] w-[50%] lg:w-[25%]">
                        <h4 className="font-bold text-lg">{player?.played}</h4>
                        <span className="text-xs text-[#91abc9]">Played</span>
                    </div>
                    <div className="p-5 border-[#ffffff29] border-b-[1px] lg:border-b-[0] lg:border-r-[1px] w-[50%] lg:w-[25%]">
                        <h4 className="font-bold text-lg">{player?.won}</h4>
                        <span className="text-xs text-[#91abc9]">Won</span>
                    </div>
                    <div className="p-5 border-[#ffffff29]  border-r-[1px] w-[50%] lg:w-[25%]">
                        <h4 className="font-bold text-lg">{player?.biggest_win}</h4>
                        <span className="text-xs text-[#91abc9]">Biggest Win</span>
                    </div>
                    <div className="p-5 w-[50%] lg:w-[25%]">
                        <h4 className="font-bold text-lg">{player?.luckiest_win}</h4>
                        <span className="text-xs text-[#91abc9]">Luckiest Win</span>
                    </div>
                    </div>
                </div>
                <div className="user-this-rounds mt-5">
                    <div className="flex items-center justify-between mt-6 mb-2">
                    <h3 className="font-bold">This Rounds</h3>
                    </div>
                    <div className="flex justify-between p-5 rounded-xl bg-[#202039]">
                    <div>
                        <div className="flex items-center gap-1 text-lg font-bold">
                        <img  className="w-[24px] h-[24px]" src={logoTon} />
                        {player?.amount}
                        </div>
                        <span className="text-xs text-[#91abc9]">Entries’ Value</span>
                    </div>
                    <div>
                        <div className="text-lg font-bold">
                        {player?.winchance}%
                        </div>
                        <span className="text-xs text-[#91abc9]">Win Chance</span>
                    </div>
                    <div>
                        <div className="text-lg font-bold">
                        {abbreviateNumber(player?.points)} Pts
                        </div>
                        <span className="text-xs text-[#91abc9]">Pts</span>
                    </div>
                    </div>
                </div>
                </div>
            </Modal>

            </div>
    );
}